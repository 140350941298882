.App {
  text-align: center;
}

  /* background: linear-gradient(194deg, #0d855f, #2d5e0c,#390633);
  background-size: 400% 400%;

  -webkit-animation: login_animate 10s ease infinite;
  -moz-animation: login_animate 10s ease infinite;
  -o-animation: login_animate 10s ease infinite;
  animation: login_animate 8s ease infinite; */

.login-float-child {
  width: 100%;
  float: left;
  background: url(.././src/img/curves.gif) center 
} 

.login-float-child2 {
  width: 50%;
  float: left;
}   

.logintext{
  color: rgb(255, 255, 255);
  /* background: -webkit-linear-gradient(245deg, rgba(0,255,246,0.8113620448179272) 3%, rgba(255,41,41,0.7469362745098039) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
}
@media screen and (max-width: 540px) {
  .login-float-child{
  width: 100%;
  }
  
.login-float-child2 {
display: none;
}  
}