:root {
    --surface-color: rgb(255, 255, 255);
    --curve: 10;

  }
  
  * {
    box-sizing: border-box;
  }
  
  body {
    font-family: 'Noto Sans JP', sans-serif;
    
    background: linear-gradient(270deg, #e4fab9, #98d4ff, #84fd8e, #76bbfc);
    background-size: 800% 800%;

    -webkit-animation: animation 15s ease infinite;
    -moz-animation: animation 15s ease infinite;
    animation: animation 15s ease infinite;
}

@-webkit-keyframes animation {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-moz-keyframes animation {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes animation {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
  
  }
  
  .cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    margin: 4rem 5vw;
    padding: 0;
    list-style-type: none;
  }
  
  .card {
    position: relative;
    display: block;
    height: 100%;  
    border-radius: calc(var(--curve) * 1px);
    overflow: hidden;
    text-decoration: none;
  }
  
  .card__image {      
    width: 100%;
    height: auto;
  }
  
  .card__overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;      
    border-radius: calc(var(--curve) * 1px);    
    background-color: var(--surface-color);      
    transform: translateY(100%);
    transition: .2s ease-in-out;
  }
  
  .card:hover .card__overlay {
    transform: translateY(0);
  }
  
  .card__header {
    position: relative;
    display: flex;
    align-items: center;
    gap: 2em;
    padding: 2em;
    border-radius: calc(var(--curve) * 1px) 0 0 0;    
    background-color: var(--surface-color);
    transform: translateY(-100%);
    transition: .2s ease-in-out;
  }
  
  .card__arc {
    width: 80px;
    height: 80px;
    position: absolute;
    bottom: 100%;
    right: 0;      
    z-index: 1;
  }
  
  /* .card__arc path {
    fill: var(--surface-color);
     path("M 40 80 c 22 0 40 -22 40 -40 v 40 Z");
  }        */
  
  .card:hover .card__header {
    transform: translateY(0);
  }
  
  .card__thumb {
    flex-shrink: 0;
    width: 50px;
    height: 50px;      
    border-radius: 0px;      
  }
  
  .card__title {
    font-size: 1em;
    margin: 0 0 .3em;
    color: #6A515E;
  }
  
  .card__tagline {
    display: block;
    margin: 1em 0;
    font-family: "MockFlowFont";  
    font-size: .8em; 
    color: #3a3a3a;  
  }
  
  .card__status {
    font-size: .8em;
    color: #585858;
  }
  
  .card__description {
    padding: 0 2em 2em;
    margin: 0;
    color: #303030;
    font-family: "MockFlowFont";   
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }    