@import url(https://cdn-uicons.flaticon.com/uicons-bold-straight/css/uicons-bold-straight.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

nav {
    display: flex; /* 1 */
    justify-content: space-between; /* 2 */
    padding: 1rem 2rem; /* 3 */
    background: #0f2a61; /* 4 */
    /* background: linear-gradient(
      to right, #ff5395, #fc9651,
       #fb57fb, #70bef9); */

  }
 
  nav ul {
    display: flex; /* 5 */
    list-style: none; /* 6 */
    font-size: large;
    
  }
  /* nav a:hover{
    background: #dd4a4a;
  } */
  
  nav li {
    padding-left: 5rem; /* 7! */
    padding-top: 2rem;
    

  }
  nav a {
    -webkit-text-decoration:solid;
            text-decoration:solid;
    color: #d9d9d9;
    
  }

  /* 
  Extra small devices (phones, 600px and down) 
*/
@media only screen and (max-width: 600px) {
    nav {
      flex-direction: column;
    }
    nav ul {
      flex-direction: column;
      padding-top: 0.5rem;
    }
    nav li {
      padding: 0.5rem 0;
    }
  } 
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 12px 16px;
    z-index: 1;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
.profileLink {
    position: fixed;
top:3%;
    left: 2%;

}


  
.webviewer {
    height: calc(100vh - 83px);
}
.webviewer {
    height: calc(100vh - 83px);
}
.webviewer {
    height: calc(100vh - 83px);
}
:root {
    --surface-color: rgb(255, 255, 255);
    --curve: 10;

  }
  
  * {
    box-sizing: border-box;
  }
  
  body {
    font-family: 'Noto Sans JP', sans-serif;
    
    background: linear-gradient(270deg, #e4fab9, #98d4ff, #84fd8e, #76bbfc);
    background-size: 800% 800%;

    -webkit-animation: animation 15s ease infinite;
    animation: animation 15s ease infinite;
}

@-webkit-keyframes animation {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes animation {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
  
  }
  
  .cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 2rem;
    gap: 2rem;
    margin: 4rem 5vw;
    padding: 0;
    list-style-type: none;
  }
  
  .card {
    position: relative;
    display: block;
    height: 100%;  
    border-radius: calc(10 * 1px);  
    border-radius: calc(var(--curve) * 1px);
    overflow: hidden;
    text-decoration: none;
  }
  
  .card__image {      
    width: 100%;
    height: auto;
  }
  
  .card__overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;      
    border-radius: calc(10 * 1px);      
    border-radius: calc(var(--curve) * 1px);    
    background-color: rgb(255, 255, 255);    
    background-color: var(--surface-color);      
    -webkit-transform: translateY(100%);      
            transform: translateY(100%);
    transition: .2s ease-in-out;
  }
  
  .card:hover .card__overlay {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  
  .card__header {
    position: relative;
    display: flex;
    align-items: center;
    grid-gap: 2em;
    gap: 2em;
    padding: 2em;
    border-radius: calc(10 * 1px) 0 0 0;
    border-radius: calc(var(--curve) * 1px) 0 0 0;    
    background-color: rgb(255, 255, 255);    
    background-color: var(--surface-color);
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    transition: .2s ease-in-out;
  }
  
  .card__arc {
    width: 80px;
    height: 80px;
    position: absolute;
    bottom: 100%;
    right: 0;      
    z-index: 1;
  }
  
  /* .card__arc path {
    fill: var(--surface-color);
     path("M 40 80 c 22 0 40 -22 40 -40 v 40 Z");
  }        */
  
  .card:hover .card__header {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  
  .card__thumb {
    flex-shrink: 0;
    width: 50px;
    height: 50px;      
    border-radius: 0px;      
  }
  
  .card__title {
    font-size: 1em;
    margin: 0 0 .3em;
    color: #6A515E;
  }
  
  .card__tagline {
    display: block;
    margin: 1em 0;
    font-family: "MockFlowFont";  
    font-size: .8em; 
    color: #3a3a3a;  
  }
  
  .card__status {
    font-size: .8em;
    color: #585858;
  }
  
  .card__description {
    padding: 0 2em 2em;
    margin: 0;
    color: #303030;
    font-family: "MockFlowFont";   
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }    
.pricecolumns {
  border: 1px solid black;
    width: 100%;
    padding: 0px;
    border-radius: 3rem;
  }
  
  .priceprice {
    border-radius: 3rem;
    list-style-type: none;
    margin: 0;
    padding: 0;
    transition: 0.3s;
  }
  
  .priceprice:hover {
    box-shadow: 10px 8px 12px 10px rgba(0, 0, 0, 0.2)
  }
  
  .priceprice .header {
    border-radius: 3rem 3rem 0 0;
    background-color: #111;
    color: white;
    font-size: 25px;
  }
  
  .priceprice li {
    border-bottom: 1px solid #eee;
    padding: 20px;
    text-align: center;
  }
  
  .priceprice .grey {
    background-color: #eee;
    font-size: 20px;
  }
  .priceprice .grey2 {
    border-radius:0 0 3rem 3rem ;
    background-color: #eee;
    font-size: 20px;
  }

  .FormGroup {
    margin: 20px 15px 20px;
    padding: 0;
    border-style: none;
    background-color: #cbd7ff;
    will-change: opacity, transform;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #829fff;
    border-radius: 4px;
  }
  
  .FormRow {
    display: flex;
    align-items: center;
    margin-left: 15px;
    border-top: 1px solid #d9e0f8;
  }


.StripeElement--webkit-autofill {
	background: transparent !important;
}

.StripeElement {
	width: 100%;
	padding: 11px 15px 11px 0;
}
.App {
  text-align: center;
}

  /* background: linear-gradient(194deg, #0d855f, #2d5e0c,#390633);
  background-size: 400% 400%;

  -webkit-animation: login_animate 10s ease infinite;
  -moz-animation: login_animate 10s ease infinite;
  -o-animation: login_animate 10s ease infinite;
  animation: login_animate 8s ease infinite; */

.login-float-child {
  width: 100%;
  float: left;
  background: url(/static/media/curves.e03c60a9.gif) center 
} 

.login-float-child2 {
  width: 50%;
  float: left;
}   

.logintext{
  color: rgb(255, 255, 255);
  /* background: -webkit-linear-gradient(245deg, rgba(0,255,246,0.8113620448179272) 3%, rgba(255,41,41,0.7469362745098039) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
}
@media screen and (max-width: 540px) {
  .login-float-child{
  width: 100%;
  }
  
.login-float-child2 {
display: none;
}  
}
